// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/misc/knowledge-base-bg-light.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#knowledge-base .knowledge-base-bg{padding:5.5rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}#knowledge-base .kb-search-input{max-width:28.125rem;background-color:#fff;border-radius:5px}#knowledge-base .kb-character-wrapper{position:relative;height:12.5rem;background:#fafafa;padding-top:1.438rem}#knowledge-base .kb-character-wrapper .v-image{position:absolute;bottom:0;left:0;right:0}#knowledge-base .kbc-title{background-color:rgba(94,86,105,.08)}#knowledge-base .kb-questions .kb-question:focus span,#knowledge-base .kb-questions .kb-question:hover span{color:var(--v-primary-base)!important}@media(max-width:600px){#knowledge-base .knowledge-base-bg{padding:1.5rem!important}}", ""]);
// Exports
module.exports = exports;
