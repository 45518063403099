<template>
  <div id="knowledge-base">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="3"
        order="2"
        order-md="1"
      >
        <v-card
          v-for="item in kbContentData"
          :key="item.character"
        >
          <v-card-title class="kbc-title align-center flex-nowrap">
            <v-icon
              :color="item.color"
              class="me-3"
            >
              {{ item.icon }}
            </v-icon>

            <span class="text-truncate">{{ item.title }} ({{ item.questions.length }})</span>
          </v-card-title>

          <div class="kb-questions py-1">
            <div
              v-for="que in item.questions"
              :key="que.question"
              class="kb-question d-flex align-start text-sm px-5 my-6 cursor-pointer"
            >
              <v-icon
                size="14"
                class="me-2 mt-1"
                color="secondary"
              >
                {{ icons.mdiCheckboxBlankCircleOutline }}
              </v-icon>
              <span class="text--secondary">{{ que.question }}</span>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="9"
        order="1"
        order-md="2"
      >
        <v-card>
          <v-card-text class="pb-0">
            <v-btn
              dark
              color="secondary"
              outlined
              @click.native="$router.push({ name: 'page-knowledge-base-category', params: { category: $route.params.category } })"
            >
              <v-icon
                left
                dark
              >
                {{ icons.mdiChevronLeft }}
              </v-icon>
              <span>Back</span>
            </v-btn>
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2">
              {{ article.titleIcon }}
            </v-icon>
            <span class="text-break">{{ article.title }}</span>
          </v-card-title>
          <v-card-text>Last updated on {{ article.lastUpdated }}</v-card-text>

          <v-card-text>
            {{ article.postDesc }}
          </v-card-text>

          <v-card-text>
            <v-img
              contain
              :src="article.postImg"
            ></v-img>
          </v-card-text>

          <v-card-text>
            <span class="text-base">{{ article.subtitle }}</span>
          </v-card-text>

          <v-card-text class="pb-3">
            {{ article.postDesc2 }}
          </v-card-text>

          <v-card-text>
            <v-list class="kb-questions py-0 ps-2">
              <v-list-item
                v-for="topic in article.postTopics"
                :key="topic"
                class="d-flex align-start cursor-pointer text-sm"
              >
                <v-icon
                  size="14"
                  class="me-2 mt-1"
                  color="secondary"
                >
                  {{ icons.mdiCheckboxBlankCircleOutline }}
                </v-icon>

                <span class="text--secondary">
                  {{ topic }}
                </span>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccount,
  mdiAccountLockOutline,
  mdiCellphoneCog,
  mdiCheckboxBlankCircleOutline,
  mdiChevronLeft,
  mdiCogOutline,
} from '@mdi/js'

export default {
  setup() {
    const kbContentData = [
      {
        category: 'account-settings',
        icon: mdiCogOutline,
        color: 'primary',
        title: 'Account Settings',
        questions: [
          { question: 'How Secure Is My Password?', slug: 'how-secure-is-my-password' },
          { question: 'Can I Change My Username?', slug: 'can-i-change-my-username' },
          { question: 'Where Can I Upload My Avatar?', slug: 'where-can-i-upload-my-avatar' },
          { question: 'How Do I Change My Timezone?', slug: 'how-do-i-change-my-timezone' },
          { question: 'How Do I Change My Password?', slug: 'how-do-i-change-my-password' },
        ],
      },
      {
        category: 'patient-chart',
        icon: mdiAccount,
        color: 'warning',
        title: 'Patient Chart',
        questions: [
          { question: 'How can I add a new Patient?', slug: 'add-patient-doc' },
          { question: 'Where do i find the Detox Forms and Results?', slug: 'detox-doc' },
          { question: 'How can I add a new Task?', slug: 'add-task-doc' },
          { question: 'Where do I find the Intake Review?', slug: 'intake-review-doc' },
        ],
      },
    ]

    const article = {
      title: 'How Secure Is My Password?',
      titleIcon: mdiAccountLockOutline,
      lastUpdated: '10 Dec 2018',
      postDesc:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam pellentesque nec nam aliquam. In est ante in nibh mauris cursus. Praesent semper feugiat nibh sed pulvinar proin gravida. Lacus laoreet non curabitur gravida arcu. Nulla pharetra diam sit amet. Vestibulum morbi blandit cursus risus at ultrices mi tempus imperdiet. Etiam sit amet nisl purus in mollis. Lorem donec massa sapien faucibus et molestie. Elementum sagittis vitae et leo duis ut diam quam nulla. Sit amet risus nullam eget felis eget nunc. Arcu dictum varius duis at consectetur lorem donec massa sapien. Vel risus commodo viverra maecenas accumsan lacus vel. Mattis vulputate enim nulla aliquet porttitor. Diam sit amet nisl suscipit adipiscing bibendum. Proin libero nunc consequat interdum. Sed faucibus turpis in eu mi bibendum neque egestas. Leo duis ut diam quam nulla porttitor massa id. Id leo in vitae turpis.',
      postImg: require('@/assets/images/pages/mac-pc.png'),
      subtitle: 'Multi Factor Authentication',
      postDesc2:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam pellentesque nec nam aliquam. In est ante in nibh mauris cursus. Praesent semper feugiat nibh sed pulvinar proin gravida. Lacus laoreet non curabitur gravida arcu. Nulla pharetra diam sit amet. Vestibulum morbi blandit cursus risus at ultrices mi tempus imperdiet. Etiam sit amet nisl purus in mollis. Lorem donec massa sapien faucibus et molestie. Elementum sagittis vitae et leo duis ut diam quam nulla. Sit amet risus nullam eget felis eget nunc. Arcu dictum varius duis at consectetur lorem donec massa sapien. Vel risus commodo viverra maecenas accumsan lacus vel. Mattis vulputate enim nulla aliquet porttitor. Diam sit amet nisl suscipit adipiscing bibendum. Proin libero nunc consequat interdum. Sed faucibus turpis in eu mi bibendum neque egestas. Leo duis ut diam quam nulla porttitor massa id. Id leo in vitae turpis.',
      postTopics: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam pellentesque nec nam aliquam.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam pellentesque nec nam aliquam.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
      ],
    }

    return {
      kbContentData,
      article,
      icons: {
        mdiCogOutline,
        mdiCheckboxBlankCircleOutline,
        mdiCellphoneCog,
        mdiChevronLeft,
        mdiAccountLockOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/knowledge-base.scss';
</style>
